import React from "react"

const LoginLayout = ({ children }) => {
    return (
        <div className="min-h-screen flex flex-col">
            <div className="grid grid-cols-1 grid-rows-1 min-h-screen">
                <div className="flex flex-col items-center bg-nordfra-500">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default LoginLayout
