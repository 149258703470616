import React from "react"
import IconProps from "./icons/types/icon-type"

export const NordfraIcon: React.FC<IconProps> = ({
    size = "48",
    ...attributes
}) => {
    const width = +size * 5 // width relative to height (from size prop)
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            width={width}
            viewBox="0 0 496.55 101.42"
            {...attributes}
        >
            <path
                d="M270.44,280.79l3.55-1.45c2.46-.94,2.46-2.31,2.46-7.53V240.47c0-2.32-.8-3.11-2.46-3.91l-2.9-1.38v-1.45h9.34l0.87,0.51c1.74,2.32,5.21,7.45,9.34,13l17,23.09a2.14,2.14,0,0,0,1.16,1.16c0.51,0,.51-0.72.51-2.1V241.56c0-3.4-.22-3.84-3-4.92l-3.91-1.45v-1.45h16.43v1.45l-2,.65c-4.2,1.45-4.13,2-4.13,7.09v28.23c0,4.49.15,9,.15,10.49l-0.43.58h-2.9l-0.87-.58c-1.16-1.45-3.47-4.63-7.09-9.48l-19.4-25.91c-0.94-1.3-1.38-2-1.74-2-0.58,0-.58.8-0.58,1.66v28.08c0,3.11-.07,4.49,2.9,5.36l4.34,1.38v1.45H270.44v-1.45"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
            <path
                d="M349.48,279.63c12,0,13.61-11.15,13.61-23.67,0-13-6.59-19.62-15.49-19.62-12.59,0-14.69,10.49-14.69,20,0,15.34,5,23.24,16.57,23.24m-23.38-21.35c0-18.24,11.73-25.48,24.18-25.48,12,0,19.62,7.75,19.62,23.31,0,18.75-10.13,27.07-23.16,27.07C333.05,283.18,326.11,273.84,326.11,258.28Z"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
            <path
                d="M389.66,254.15c0,2.17.22,2.17,4.78,2.17,7.74,0,11.51-2.6,11.51-9.77-0.22-8-4.71-10.06-11.51-10.06-4.78,0-4.78.43-4.78,3.55v14.11m-11.8,26.64,3.4-1.16c2.39-.72,2.39-1.59,2.39-6.15V243.15c0-5.28,0-6.08-2.39-6.88l-3.4-1.08v-1.45h22.65c7.31,0,12.09,2.9,12.09,10.64,0,12.23-10.42,11.8-10.42,13.53a3,3,0,0,0,.8,1.67l9,13.1c3.47,5.14,4.7,6.44,7.82,8.11v1.45h-6.51a5.08,5.08,0,0,1-4.49-2.53l-10.42-16.57c-2.24-3.55-2.39-3.91-5.43-3.91h-1.3c-1.59,0-2,.22-2,2.1v12.16c0,5.14-.07,5.28,2.39,6.15l2.82,1.16v1.45h-17v-1.45Z"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
            <path
                d="M482.09,280.79l3.4-1.16c2.32-.72,2.39-1.23,2.39-6.88v-29.6c0-5.21,0-6.08-2.39-6.88l-3.4-1.08v-1.45h34l0.58,0.51v10.86h-2l-1.81-3.33c-2.46-4.42-2.32-5.14-11.44-5.14-7.6,0-7.6.29-7.6,3.62v13.83c0,2.1.29,2.1,3.11,2.1h5.5c3,0,3.47-.44,4.85-3.69l1-2.39h1.45v15h-1.45l-1.23-2.75c-1.45-3.18-1.74-3.26-4.92-3.26h-5.28c-1.88,0-3,0-3,1.74v11.94c0,5.5,0,6,1.88,6.59l4.27,1.45v1.45h-18v-1.45"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
            <path
                d="M537,254.15c0,2.17.22,2.17,4.78,2.17,7.74,0,11.51-2.6,11.51-9.77-0.22-8-4.71-10.06-11.51-10.06-4.78,0-4.78.43-4.78,3.55v14.11m-11.8,26.64,3.4-1.16c2.39-.72,2.39-1.59,2.39-6.15V243.15c0-5.28,0-6.08-2.39-6.88l-3.4-1.08v-1.45h22.66c7.31,0,12.09,2.9,12.09,10.64,0,12.23-10.42,11.8-10.42,13.53a3,3,0,0,0,.8,1.67l9,13.1c3.47,5.14,4.71,6.44,7.82,8.11v1.45h-6.51a5.08,5.08,0,0,1-4.49-2.53l-10.42-16.57c-2.24-3.55-2.39-3.91-5.43-3.91H539c-1.6,0-2,.22-2,2.1v12.16c0,5.14-.07,5.28,2.39,6.15l2.82,1.16v1.45h-17v-1.45Z"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
            <path
                d="M590.08,257.56a3.86,3.86,0,0,0-.36,1.37c0,0.8.65,0.8,2.1,0.8H601c1.08,0,1.59-.29,1.59-1a4.86,4.86,0,0,0-.36-1.09L597.54,243c-0.51-1.52-.65-2-1.09-2s-0.65.72-.87,1.3l-5.5,15.2m-17.15,23.23,2.46-1.09c2.82-1.23,3-3.11,4.56-6.88l15.7-38.8,0.44-.29h3.76l0.36,0.29,13.54,39.23c1.52,4.42,1.37,5.29,4.56,6.59l2.24,0.94v1.45H603v-1.45l4.05-1.23c1-.29,1.81-0.72,1.81-1.59a8.07,8.07,0,0,0-.58-2.17l-3.55-11c-0.65-2-1.3-2.17-3.26-2.17H591.6c-3,0-3.55.14-4.78,3.69l-2.46,7.17a13.41,13.41,0,0,0-1.09,4.13c0,1.16.58,1.45,2.1,2l3.69,1.23v1.45H572.93v-1.45Z"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
            <path
                d="M150.92,258A50.45,50.45,0,0,0,172,297.08c-1.61-9.32-5-24.85-10.7-31.63-2.28-2.72-6.06-5.23-10.37-7.46"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#a1d1f1" }}
            />
            <path
                d="M213.81,231.37L242,224a51.7,51.7,0,0,0-88.58,16.41l64.9-17.08-4.5,8.08"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#a1d1f1" }}
            />
            <path
                d="M210,301.74s-3.42-29.09-11.82-39.1c-0.34-.4-0.71-0.8-1.11-1.19l-21.23,38.17a51.67,51.67,0,0,0,26.42,7.21c28.38,0,51.39-22.7,51.39-50.71a49.92,49.92,0,0,0-5.54-22.89L210,301.74"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#a1d1f1" }}
            />
            <path
                d="M160.91,245.29l52.9-13.92,4.5-8.08L124,248.1s15.34,3.9,26.91,9.89c4.3,2.23,8.09,4.75,10.37,7.46,5.69,6.78,9.09,22.31,10.7,31.63,0.77,4.44,1.12,7.47,1.12,7.47l24-43.1c-9.56-9.4-36.17-16.16-36.17-16.16"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
            <path
                d="M160.91,245.29s26.61,6.76,36.17,16.16c0.4,0.39.77,0.79,1.11,1.19,8.4,10,11.82,39.1,11.82,39.1l38.11-68.51s-2.06-3.52-2.9-4.77S242,224,242,224l-81.08,21.34"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#fffffc" }}
            />
            <path
                d="M454.93,255.91v2.65H440.83v20.5a33.5,33.5,0,0,0,7.64,1c12.57,0,19.25-9.92,19.25-21.68,0-12.05-6.76-21.68-19.55-21.68a27.29,27.29,0,0,0-7.35,1v18.22h14.11m-28,2.65v-2.65h7V243.34c0-6.17,0-8-6.91-8V234h18.81c6.69,0,14.62.22,20.36,4,6.69,4.48,9.77,12.2,9.77,20.06,0,13.3-8.67,24.54-26.31,24.54H427v-1.32c6.91,0,6.91-1.84,6.91-8V258.56h-7Z"
                transform="translate(-124.01 -205.41)"
                style={{ fill: "#004685" }}
            />
        </svg>
    )
}
